import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  metrics: icon('ic_metrics'),
  dashboard: icon('ic_dashboard'),
  appointment: icon('ic_appointment'),
  ai: icon('ic_ai'),
  history: icon('ic_history'),
  mycare: icon('ic_mycare'),
  listings: icon('ic_listings'),
  providers: icon('ic_providers'),
  primary_care: icon('ic_primary_care'),
  help: icon('ic_help'),
  subscription: icon('ic_subscription'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const authContext = useAuthContext();
  const userPackage = authContext?.user?.userPackage?.toLowerCase() || '';

  const data = useMemo(
    () => [
      // OVERVIEW
      {
        subheader: t('quick links'),
        items: [
          {
            title: t('Home'),
            path: paths.dashboard.root,
            icon: <Iconify icon="icomoon-free:home" />,
            disabled: false,
          },
          {
            title: t('Appointments'),
            path: paths.dashboard.appointments.root,
            icon: ICONS.appointment,
            disabled: false,
          },
          {
            title: t('Vital Records'),
            path: paths.dashboard.general.metrics,
            icon: ICONS.metrics,
            disabled: false,
          },
          {
            title: t('AI Chat'),
            path: paths.dashboard.aiChat,
            icon: ICONS.ai,
            disabled: false,
          },
          {
            title: t('My Care'),
            path: paths.dashboard.general.mycare,
            icon: <Iconify icon="streamline:health-care-2-solid" />,
            disabled: userPackage === 'free',
          },
          {
            title: t('Profile'),
            path: paths.dashboard.user.profile,
            icon: <Iconify icon="mdi:user-circle" />,
            disabled: false,
          },
          {
            title: t('Account Settings'),
            path: paths.dashboard.user.account,
            icon: <Iconify icon="streamline:cog-solid" />,
            disabled: false,
          },
        ],
      },

      // MANAGEMENT
      {
        subheader: t('services'),
        items: [
          {
            title: t('My Physician'),
            path: paths.dashboard.primaryCare.root,
            icon: ICONS.primary_care,
            disabled:
              userPackage === 'basic' || userPackage === 'free' || userPackage === 'standard',
          },
          {
            title: t('Facilities'),
            path: paths.dashboard.facilities.root,
            icon: ICONS.listings,
            disabled: false,
            children: [
              { title: t('Hospitals'), path: paths.dashboard.facilities.hospitalsList },
              {
                title: t('Diagnostic Centers'),
                path: paths.dashboard.facilities.diagnosticCentersList,
              },
            ],
          },
          {
            title: t('Find a Specialist'),
            path: paths.dashboard.providers.root,
            icon: ICONS.providers,
            disabled: false,
          },
          {
            title: t('Subscription'),
            path: paths.pricing,
            icon: ICONS.subscription,
            disabled: false,
          },
        ],
      },
    ],
    [t, userPackage]
  );

  return data;
}
