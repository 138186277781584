import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// hooks
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hooks';
import { useSnackbar } from 'src/components/snackbar';

// locales
import { useLocales } from 'src/locales';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { logout } = useAuthContext();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useBoolean();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const { t } = useLocales();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Button variant="contained" color="primary" onClick={confirm.onTrue}>
          {t('Logout')}
        </Button>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Logout"
        content={<>Are you sure want to logout?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleLogout();
              confirm.onFalse();
            }}
          >
            Yes
          </Button>
        }
      />
    </Stack>
  );
}
