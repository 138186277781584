import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// Illustrations
import AuthClassicAltLayout from 'src/layouts/auth/classic-alt';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordView = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));
const JwtResetPasswordPage = lazy(() => import('src/pages/auth/jwt/reset-password'));
const JwtOTPVerifyPage = lazy(() => import('src/pages/auth/jwt/otp-verify'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout
          show
          title="New Here?"
          text="Join us on a comprehensive journey to better health."
        >
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicAltLayout
          show
          title="One of Us?"
          text=" If you have an account, sign in to make the most of our health services."
        >
          <JwtRegisterPage />
        </AuthClassicAltLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthClassicAltLayout
          show={false}
          title="Lost Password?"
          text="It happens to the best of us. Don't worry, we've got you covered"
        >
          <JwtForgotPasswordView />
        </AuthClassicAltLayout>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <AuthClassicLayout
          show={false}
          title="New Password?"
          text="Create a secure and unique password for your account."
        >
          <JwtResetPasswordPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'verify',
      element: (
        <AuthClassicLayout
          show={false}
          text="Reset your password by following the instructions on the right."
          title=""
        >
          <JwtVerifyPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'otp-verify',
      element: (
        <AuthClassicLayout text="Enter the otp you received from Telical" title="">
          <JwtOTPVerifyPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
